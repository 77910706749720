export default async ({ app, $axios, redirect }) => {
  app.$auth.onRedirect((to, from) => {
    return app.localePath(to)
  })

  if (!app.$auth || !app.$auth.loggedIn) {
    return
  }

  $axios.onError((error) => {
    const code = parseInt(error.response?.status)
    const isUnAuthenticated =
      error.response?.status && error.response?.data?.code === 'auth.unauthenticated' && code === 401

    if (isUnAuthenticated) {
      app.$auth.logout().then(() => {
        redirect(app.localePath('/'))
      })
    }
  })

  try {
    if (app?.$fire?.appCheck) {
      await app.$fire.appCheck.activate(process.env.FIREBASE_RECAPTCHA_V3_SITE_KEY, true)

      const response = await app.$fire.appCheck.getToken()

      if (response?.token) {
        $axios.onRequest((config) => {
          if (app?.$auth?.loggedIn) {
            config.headers.common['X-Firebase-AppCheck'] = response.token
          }
        })
      }
    }
  } catch (err) {
    console.error({ error: err })
  }

  await app.store.dispatch('userSettings/fetch_settings')
}
