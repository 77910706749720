<template>
  <span v-if="!$route.query.web_view" class="c--shared_footer">
    <template v-if="!isFooterClosed">
      <div
        v-show="hasMobileFooter"
        :class="[
          'mobile-bottom-menu',
          {
            'menu-down-hide': !mobileBottomMenuView,
          },
        ]"
      >
        <div class="bottom-menu-nodes-container">
          <nuxt-link
            tag="div"
            v-for="(footerNode, index) in mobileTabs"
            :key="'footerNode-' + index"
            :to="localePath(footerNode.link)"
            class="bottom-menu-node"
          >
            <i :class="['tw-block', footerNode.ico]" />
            <div class="bottom-menu-node-text tw-text-2.5 tw-leading-[11px] tw-pt-px">
              {{ footerNode.label }}
            </div>
          </nuxt-link>
        </div>
      </div>

      <footer
        :class="[
          'footer-container home-container-padding',
          {
            'mb-64': deviceType.isMobile,
          },
        ]"
      >
        <div class="container-fluid">
          <div class="new-container mx-auto">
            <div class="footer-top-links d-grid gap-15 gap-sm-30 grid-cols-1 grid-cols-sm-12">
              <div :class="['footer-top-links-block', infoColClass]">
                <FooterLogo :branding="branding" />
                <span class="footer-link-info">
                  {{ $t('components.footer.short-info') }}
                </span>
              </div>
              <div
                v-if="footerCountriesLinks && footerCountriesLinks.length > 0"
                :class="['footer-top-links-block footer-collapse-mobile', topLinksColClass]"
              >
                <span v-b-toggle="`accordion-link-countires`" class="linkTitle mb-0">
                  {{ $t('components.footer.popular-countries') }}
                </span>
                <b-collapse id="accordion-link-countires">
                  <div class="d-flex flex-column collapse-list">
                    <SharedLink
                      v-for="(item, index) in footerCountriesLinks"
                      :key="`footer_top_links_categories_mobile_${index}`"
                      :to="localePath(`/${item.slug}-esim`)"
                      class="footer-top-links-item-link"
                      @click="sendEventHandler(item)"
                    >
                      {{ item.title }}
                    </SharedLink>
                  </div>
                </b-collapse>
              </div>
              <div
                v-for="(link, index) in topLinks"
                :class="['footer-top-links-block footer-collapse-mobile', topLinksColClass]"
                :key="`footer_top_links_${index}_mobile`"
              >
                <span v-b-toggle="`accordion-link-${index}`" class="linkTitle mb-0">
                  {{ link.title }}
                </span>
                <b-collapse :id="`accordion-link-${index}`">
                  <div class="d-flex flex-column collapse-list">
                    <SharedLink
                      v-for="(item, subIndex) in link.subLinks"
                      :key="`footer_top_links_${index}_mobile_${subIndex}`"
                      :to="item.selfUrl ? item.link : localePath(item.link)"
                      :external="item.external"
                      :self-url="item.selfUrl"
                      :button="item.button"
                      :class="[item.button ? 'footer-top-links-item-button' : 'footer-top-links-item-link']"
                      @click="cookieSettingsHandler"
                    >
                      {{ item.title }}
                    </SharedLink>
                  </div>
                </b-collapse>
              </div>
              <div
                v-if="footerCountriesLinks && footerCountriesLinks.length > 0"
                :class="['footer-top-links-block footer-no-collapse', topLinksColClass]"
              >
                <span class="linkTitle">
                  {{ $t('components.footer.popular-countries') }}
                </span>
                <SharedLink
                  v-for="(item, index) in footerCountriesLinks"
                  :key="`footer_top_links_categories_${index}`"
                  :to="localePath(`/${item.slug}-esim`)"
                  class="footer-top-links-item-link"
                  @click="sendEventForCountryHandler(item)"
                >
                  {{ item.title }}
                </SharedLink>
              </div>
              <div
                v-for="(link, index) in topLinks"
                :class="['footer-top-links-block footer-no-collapse', topLinksColClass]"
                :key="`footer-top-links-item-${index}`"
              >
                <span class="linkTitle">
                  {{ link.title }}
                </span>
                <template v-for="(item, subIndex) in link.subLinks">
                  <button
                    v-if="item.button"
                    :key="`footer_top_links_${index}_${subIndex}_button`"
                    class="footer-top-links-item-button"
                    @click="cookieSettingsHandler(item)"
                  >
                    {{ item.title }}
                  </button>

                  <SharedLink
                    v-else
                    :key="`footer_top_links_${index}_${subIndex}_link`"
                    :to="item.link"
                    :external="item.external"
                    :self-url="item.selfUrl"
                    class="footer-top-links-item-link"
                    @click="sendEventHandler(item)"
                  >
                    {{ item.title }}
                  </SharedLink>
                </template>
              </div>
            </div>
            <div class="footer-bottom-links">
              <div class="footer-bottom-links-left">
                <SharedLink
                  v-for="(item, index) in bottomLeftLinks"
                  :key="'footer_bottom_links_' + index"
                  :to="item.link"
                  :external="item.external || false"
                  :self-url="item.selfUrl || false"
                  @click="sendEventHandler(item)"
                >
                  {{ item.title }}
                </SharedLink>
                <span class="footer-language-btn" @click="languagesModalView = true">
                  <i
                    :class="[
                      'airalo-icon-globe tw-text-zinc-500 tw-text-4',
                      {
                        'mr-10 mr-md-5px':
                          currentLangText.native.length > 0 &&
                          (currentLangText.dir === 'ltr' || currentLangText.dir === ''),
                        'ml-10 ml-md-5px': currentLangText.native.length > 0 && currentLangText.dir === 'rtl',
                      },
                    ]"
                    :data-testid="`${currentLangText.native}-footer-language`"
                  />
                  <template v-if="currentLangText.native.length > 0">
                    {{ currentLangText.native }}
                  </template>
                </span>
                <span
                  v-if="currentCurrency"
                  class="footer-currency-btn"
                  data-testid="footer-currency-button"
                  @click="currenciesModalView = true"
                >
                  {{ currentCurrency }}
                </span>

                <currencies-modal
                  :modal-view="currenciesModalView"
                  :currencies="currencies"
                  :title="$t('components.currencies.modal.title')"
                  :search-input-placeholder="$t('placeholder.currencies.modal.currency')"
                  :empty-text="$t('global.no-currency-available')"
                  @onCloseCurrenciesModal="onCloseCurrenciesModal"
                />

                <languages-modal
                  :modal-view="languagesModalView"
                  :languages="$i18n.locales"
                  :title="$t('components.languages.modal.title')"
                  :search-input-placeholder="$t('placeholder.languages.modal.language')"
                  :empty-text="$t('global.no-language-available')"
                  @onCloseLanguagesModal="languagesModalView = false"
                />
              </div>
              <div class="footer-bottom-links-right">
                <span class="footer-follow-text">{{ $t('components.footer.follow') }}</span>
                <a
                  v-for="(social, index) in socials"
                  :key="'footer-social-nav-' + index"
                  target="_blank"
                  :href="social.link"
                  class="footer-social-link d-flex"
                >
                  <SvgIcon :name="social.name" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-payment-methods-container tw-bg-white">
          <div class="footer-payment-methods" v-for="(paymentsRow, prIdx) in payments">
            <span
              v-for="(payment, index) in paymentsRow"
              :key="'footer-bottom-icons-' + index"
              class="footer-bottom-icons d-flex"
            >
              <SvgIcon :name="payment" />
            </span>
          </div>
        </div>
      </footer>
    </template>

    <UiBrandingTopline v-if="branding" :branding="branding" />
  </span>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CurrenciesModal from '~/components/modals/currencies-modal'
  import LanguagesModal from '~/components/modals/languages-modal'
  import SharedLink from '~/components/shared/link.vue'
  import SvgIcon from '~/components/shared/svg-icon/svg-icon'
  import FooterLogo from '~/components/UI/FooterLogo.vue'
  import UiBrandingTopline from '@/components/UI/Branding/Topline.vue'

  export default {
    components: {
      UiBrandingTopline,
      CurrenciesModal,
      LanguagesModal,
      SharedLink,
      SvgIcon,
      FooterLogo,
    },

    props: {
      isFooterClosed: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        socials: [
          {
            link: 'https://www.facebook.com/airalocom',
            name: 'facebook',
          },
          {
            link: 'https://www.instagram.com/airalocom',
            name: 'instagram',
          },
          {
            link: 'https://twitter.com/airalocom',
            name: 'x',
          },
          {
            link: 'https://tiktok.com/@airalocom',
            name: 'tiktok',
          },
          {
            link: 'https://www.linkedin.com/company/airalocom',
            name: 'linkedin',
          },
          {
            link: 'https://www.youtube.com/channel/UCPL_PzFyZLgMOWOLIpNlkMw ',
            name: 'youtube',
          },
        ],

        payments: [
          ['visa', 'mastercard', 'amex', 'union'],
          ['paypal', 'alipay', 'jcb'],
        ], // See UXP-2780

        bottomLeftLinks: [
          {
            title: this.$t('components.footer.copyright'),
            link: '',
            external: false,
          },
          {
            title: this.$t('components.footer.privacy'),
            link: '/more-info/privacy-policy',
            external: false,
          },
          {
            title: this.$t('components.footer.terms'),
            link: '/more-info/terms-conditions',
            external: false,
          },
        ],

        mobileTabs: [
          {
            id: 1,
            link: '/',
            label: this.$t('components.footer.store'),
            ico: 'airalo-icon-store tw-text-5.5 tw-leading-none tw-mt-0.5 tw-mb-1',
          },
          {
            id: 2,
            link: '/my-esims',
            label: this.$t('components.footer.my-esims'),
            ico: 'airalo-icon-myesim tw-text-7 tw-leading-none',
            redirect: true,
          },
          {
            id: 3,
            link: '/profile',
            label: this.$t('components.footer.profile'),
            ico: 'airalo-icon-profile tw-text-7 tw-leading-none',
            redirect: true,
          },
        ],

        currenciesModalView: false,
        languagesModalView: false,

        utils: {
          scrollLastPos: 0,
        },

        mobileBottomMenuView: true,

        resizeDebounce: null,
      }
    },

    computed: {
      ...mapGetters({
        branding: 'coBranding/getBranding',
        isCoBranded: 'coBranding/getIsCoBranded',
      }),

      currencies() {
        return this.$store.getters['multiCurrency/get_available_currencies']
      },

      currentCurrency() {
        return this.$store.getters['multiCurrency/get_current_currency_symbol_code']
      },

      deviceType() {
        return this.$store.getters.get_device_types
      },

      footerCountriesLinks() {
        return this.$store.getters.get_footer_countries_links.filter((_v, i) => i < 10)
      },

      infoColClass() {
        if (this.isCoBranded) {
          return 'col-span-sm-12 col-span-lg-3'
        } else {
          return !this.footerCountriesLinks || this.footerCountriesLinks.length === 0
            ? 'col-span-sm-12 col-span-lg-3'
            : 'col-span-sm-12 col-span-lg-2'
        }
      },

      topLinksColClass() {
        if (this.isCoBranded) {
          return 'col-span-sm-12 col-span-lg-3'
        } else {
          return !this.footerCountriesLinks || this.footerCountriesLinks.length === 0
            ? 'col-span-sm-4 col-span-lg-3'
            : 'col-span-sm-6 col-span-lg-2'
        }
      },

      hasMobileFooter() {
        return this.deviceType.isMobile || this.deviceType.isTablet
      },

      generatedTopLinks() {
        return this.topLinks.map((topLink) => {
          return {
            ...topLink,
            subLinks: topLink.subLinks
              .map((subLink) => {
                if (
                  subLink.link === '/profile/identity-authentication/default' &&
                  this.$store.state.auth.loggedIn &&
                  !this.$store.getters['eKyc/get_user_default_identities']
                ) {
                  return null
                }

                return subLink
              })
              .filter(Boolean),
          }
        })
      },

      currentLangText() {
        return this.$i18n.locales.find((v) => v.code === this.$i18n.locale)
      },

      topLinks() {
        return [
          ...(!this.isCoBranded
            ? [
                {
                  title: this.$t('components.footer.partner-with-us'),
                  subLinks: [
                    {
                      title: this.$t('components.footer.partner.with.us.airalo-partner-hub'),
                      link: '/partner-with-us/airalo-partner-hub',
                      external: false,
                    },
                    {
                      title: this.$t('components.footer.partner.with.us.affiliate-partners'),
                      link: '/partner-with-us/affiliate-partners',
                      external: false,
                    },
                    {
                      title: this.$t('components.footer.partner.with.us.distrubition-partners'),
                      link: '/partner-with-us/distrubition-partners',
                      external: false,
                    },
                    {
                      title: this.$t('components.footer.partner.with.us.api-partners'),
                      link: '/partner-with-us/api-partners',
                      external: false,
                    },
                    {
                      title: this.$t('components.footer.partner.with.us.airalo-for-corporates'),
                      link: '/partner-with-us/airalo-for-corporates',
                      external: false,
                    },
                  ],
                },
              ]
            : []),
          {
            title: this.$t('components.footer.about-us'),
            subLinks: [
              {
                title: this.$t('components.footer.about.us.about-airalo'),
                link: '/about-us/about-airalo',
                external: false,
              },
              {
                title: this.$t('components.main.navigation.about.us.our-values'),
                link: '/about-us/our-values',
                external: false,
              },
              {
                title: this.$t('components.footer.about.us.impact'),
                link: '/about-us/impact',
                external: false,
              },
              {
                title: this.$t('components.footer.about.us.newsroom'),
                link: '/about-us/newsroom',
                external: false,
              },
              {
                title: this.$t('components.footer.about.us.media-center'),
                link: '/about-us/media-center',
                external: false,
              },
            ],
          },
          {
            title: this.$t('components.footer.more-info'),
            subLinks: [
              {
                link: '/help',
                title: this.$t('components.footer.help-center'),
                external: true,
              },
              {
                link: '/blog',
                title: this.$t('components.footer.blog'),
                external: false,
              },
              {
                link: '/referral',
                title: this.$t('components.footer.referral-page'),
                external: false,
              },
              {
                title: this.$t('components.footer.manage-cookie'),
                button: true,
              },
            ].map((item) => this.$utils.getShortLocalesCodeForHelp(item, 'link')),
          },
          ...(!this.isCoBranded
            ? [
                {
                  title: this.$t('components.sub.navigation.airalo-careers'),
                  subLinks: [
                    {
                      link: '/airalo-careers/working-at-airalo',
                      title: this.$t('components.main.navigation.airalo.careers.working-at-airalo'),
                      external: false,
                    },
                    {
                      link: '/airalo-careers/job-vacancies',
                      title: this.$t('components.main.navigation.airalo.careers.job-vacancies'),
                      external: false,
                    },
                  ],
                },
              ]
            : []),
        ]
      },
    },

    methods: {
      sendEventForCountryHandler(item) {
        this.$utils.sendEvent('footerNavigationV2Event', {
          text: item.title,
          url: this.localePath(`/${item.slug}-esim`),
        })
      },
      sendEventHandler(item) {
        this.$utils.sendEvent('footerNavigationV2Event', {
          text: item.title,
          url: this.localePath(item.link),
        })
      },
      cookieSettingsHandler(item) {
        if (window?.OneTrust) {
          window.OneTrust.ToggleInfoDisplay()

          this.$utils.sendEvent('footerNavigationV2Event', {
            text: this.$t('components.footer.manage-cookie'),
            url: 'cookie-consent-button',
          })
        }
      },

      onCloseCurrenciesModal() {
        this.currenciesModalView = false
      },

      setRedirectUrl(item) {
        if (process.client && !this.$store.state.auth.loggedIn && item?.redirect) {
          localStorage.setItem('redirect-url', item.slug ? item.slug : item.link)
        }
      },

      handleScroll() {
        const currY = window.scrollY || document.documentElement.scrollTop

        this.mobileBottomMenuView = this.utils.scrollLastPos < 64 || currY < this.utils.scrollLastPos

        this.utils.scrollLastPos = currY <= 0 ? 0 : currY
      },
    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll)
    },

    mounted() {
      if (process.client) {
        if (!this.deviceType.isDesktop && !this.deviceType.isTablet) {
          window.document.addEventListener('scroll', this.handleScroll)
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .footer-top-links-item-button {
    letter-spacing: 0;
    line-height: 18px;
    color: #8a8a8a;
    font-size: 13px;
    background-color: transparent;
    padding: 0;
    border: none;
    text-align: left;

    [dir='rtl'] & {
      text-align: right;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .ot-sdk-cookie-settings {
    background-color: transparent;
    color: #4a4a4a;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 14px;
    border: none;
  }

  .mobile-bottom-menu {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
  }

  .bottom-menu-nodes-container {
    display: flex;

    position: absolute;
    bottom: 0;

    width: 100%;
    height: 4rem;

    background: white;
    box-shadow: 0px -10px 10px -4px rgba(0, 0, 0, 0.08);
    transition: bottom 0.15s ease-in-out;
  }

  .mobile-bottom-menu.menu-down-hide .bottom-menu-nodes-container {
    bottom: -64px;
  }

  .bottom-menu-node {
    opacity: 0.5;
    flex: 1 1 0;
    text-align: center;
    padding-top: 0.6rem;
  }

  .bottom-menu-node.nuxt-link-exact-active.nuxt-link-active {
    opacity: 1;
  }

  .bottom-menu-node.nuxt-link-exact-active.nuxt-link-active .bottom-menu-node-text {
    font-weight: 600;
  }

  .footer-container {
    border-top: 1px solid #eeeeee;
    padding-bottom: 0;

    .footer-top-links {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 40px;

      &-block {
        display: flex;
        flex-direction: column;

        .collapse {
          display: flex;
          flex-direction: column;

          &-list {
            padding-top: 10px;
          }
        }

        *:not(:last-child, .footer-logo, .footer-ignore) {
          margin-bottom: 10px;
        }
        .footer-link-info {
          color: #8a8a8a;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 18px;
          margin-top: 20px;
        }
        .linkTitle {
          color: #4a4a4a;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
        }
        .footer-top-links-item-link {
          letter-spacing: 0;
          line-height: 18px;
          color: #8a8a8a;
          font-size: 13px;
        }
      }
    }
    .footer-bottom-links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 19px 0;

      &-left {
        display: grid;
        grid-auto-flow: column;
        gap: 20px;
        justify-self: start;
        align-items: center;
        padding: 5px 0;

        .footer-language-btn {
          display: flex;
          align-items: center;
          color: #4a4a4a;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 21px;
          border-left: 1px solid #dddddd;
          padding-left: 20px;
          cursor: pointer;
          [dir='rtl'] & {
            border-left: none;
            border-right: 1px solid #dddddd;
            padding-left: 0;
            padding-right: 20px;
          }
        }

        span:not(.airalo-icon-wrapper, .footer-language-btn),
        a {
          color: #4a4a4a;
          font-size: 11px;
          font-weight: 600;
          letter-spacing: 1px;
          line-height: 14px;
        }
      }

      &-right {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-flow: column;
        gap: 20px;
        align-items: center;
        justify-items: end;
        padding: 5px 0;

        span {
          color: #4a4a4a;
          font-size: 11px;
          font-weight: 600;
          letter-spacing: 1px;
          line-height: 14px;
        }
      }
    }

    .footer-payment-methods-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 20px;
      justify-content: center;
      padding: 20px;
    }
    .footer-payment-methods {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .footer-bottom-icons {
        div {
          width: 44px;
          height: 28px;
        }
      }
    }

    .footer-collapse-mobile {
      display: none;
    }

    .footer-no-collapse {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    .footer-container {
      .footer-bottom-links {
        display: grid;
        grid-auto-flow: row;
        gap: 20px;

        justify-content: unset;
        align-items: unset;
        flex-wrap: unset;

        &-left {
          order: 2;
        }

        &-right {
          justify-items: start;
          grid-template-columns: none;
          grid-auto-columns: max-content;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .footer-container {
      .footer-top-links {
        border-bottom: none;
        padding-bottom: 20px;

        &-block {
          .footer-link-info {
            margin-bottom: 20px;
          }

          &.footer-collapse-mobile {
            display: flex;
            border-bottom: 1px solid #eee;
            padding-bottom: 15px;

            .linkTitle {
              position: relative;

              &:after {
                content: '\e914';
                color: #dddddd;
                font-family: 'airalo-icomoon', sans-serif;
                font-size: 12px;
                position: absolute;
                right: 0;

                [dir='rtl'] & {
                  right: unset;
                  left: 0;
                }
              }

              &.not-collapsed:after {
                content: '\e913';
                font-family: 'airalo-icomoon', sans-serif;
                font-size: 12px;
                color: #4a4a4a;
              }
            }
          }
        }
      }
      .footer-bottom-links {
        padding-top: 0;

        &-left {
          grid-auto-flow: unset;
          span {
            grid-column: span 2 / span 2;
            order: 2;
          }
          .footer-language-btn {
            border-left: none;
            padding-left: 0;
            .airalo-icon-wrapper {
              order: 0;
            }
          }
        }
      }

      .footer-no-collapse {
        display: none;
      }
    }
  }
</style>
