import ServerSideCookies from 'cookies'
import Vue from 'vue'

const showConsoleErrors = process.env.APP_ENVIRONMENT === 'local'

export const state = () => ({
  app_theme_mode: 'Light', // ['Light, Dark']
  auth_dialogs_email: '',
  auth: {},
  banner_view: false,
  campaign_promotion: null,
  crKey: 1,
  current_breadcrumb_text: '',
  footer_countries_links: [],
  ip: '0.0.0.0',
  is_first_login: false,
  loaded_archived_segment: false,
  loaded_normal_segment: false,
  logout_modal_visibility: false,
  mobile_header_text: '',
  searched: false,
  selected_product_type: '',
  should_show_signup_reward: false,
  show_preloader: false,
  static_content: [],
  user_current_password: '',
  user_esims: [],
  user_info: {},
  user_welcome: {},
  // bannerViewComponentName: '',
  // countries: [],
  // installation: {},
  // order: {},
  // regionsByName: [],
  // selected_user_eSim: {},
  // tmp_selected_user_eSim: {},
  // should_show_eSIM_settings: false,
  // show_esim_archive_confirmation_modal: false,
  // show_esim_delete_confirmation_modal: false,
  // show_esim_rename_modal: false,
  // show_esim_unarchive_confirmation_modal: false,

  breadcrumb_mapping: {
    '/profile/settings': ['components.breadcrumb.profile', 'components.breadcrumb.account-information'],
    '/profile/identity-authentication/default': [
      'components.breadcrumb.profile',
      'components.breadcrumb.identity-authentication',
    ],
    '/profile/identity-authentication/onetime': [
      'components.breadcrumb.profile',
      'components.breadcrumb.identity-authentication',
    ],
    '/profile/airmoney': ['components.breadcrumb.profile', 'components.breadcrumb.airmoney-voucher'],
    '/profile/credit-cards': ['components.breadcrumb.profile', 'components.breadcrumb.saved-cards'],
    '/profile/change-email': [
      'components.breadcrumb.profile',
      'components.breadcrumb.account-information',
      'components.breadcrumb.change-email',
    ],
    '/profile/change-password': [
      'components.breadcrumb.profile',
      'components.breadcrumb.account-information',
      'components.breadcrumb.change-password',
    ],
    '/more-info/privacy-policy': ['components.sub.navigation.title', 'components.breadcrumb.privacy'],
    '/more-info/terms-conditions': ['components.sub.navigation.title', 'global.terms-conditions'],
    '/more-info/about': ['components.sub.navigation.title', 'components.breadcrumb.about'],
  },
  content_route_mappings: {
    1: '/more-info/about',
    2: '/more-info/terms-conditions',
    3: '/more-info/privacy-policy',
    4: '',
    5: '',
  },
  device_types: {
    isDesktop: true,
    isMobile: false,
    isTablet: false,
  },
  endpoints: {
    campaign_terms: '/v2/campaign-terms/{campaignSlug}',
    checkout_airmoneys: '/v4/checkouts/{checkoutId}/airmoneys',
    checkout_complete: '/v2/checkouts/{checkoutId}/complete',
    checkout_details: '/v4/checkouts/{checkoutId}',
    checkout_pay: '/v4/checkouts/{checkoutId}/pay',
    checkout_promotions: '/v4/checkouts/{checkoutId}/promotions',
    checkouts: '/v4/checkouts',
    contents_group: '/v2/contents?group={groupSlug}',
    contents_page: '/v2/contents/{pageSlug}',
    contents: 'content',
    countries: '/v3/countries',
    credit_card: '/v2/users/me/cards/{creditCardId}',
    credit_cards: '/v2/users/me/cards',
    delete_reasons: '/v2/deletereasons',
    email_verify: '/v4/auth/email/verify',
    esim_topup_packages: '/v4/users/me/sims/{esimId}/topups',
    export_sar: '/users/me/export-sar',
    footer_countries_links: '/v3/countries/?type=popular&featured_type=general',
    global_esims: 'v3/regions/world',
    installation: 'installation',
    jobs: '/v2/jobs?limit={jobsLimit}',
    operator_topup_packages: '/v4/operator/{operatorId}/package?type=topup',
    order_details_v3: '/v3/users/me/orders/{orderId}',
    order_details_v4: '/v4/users/me/orders/{orderId}',
    orders: '/v4/users/me/orders',
    package_details: '/v4/packages/{packageId}',
    regional_esims: 'v3/regions',
    regions: 'region',
    setup_intent: '/v2/users/me/cards/setup',
    social_login: '/v4/auth/social/{authStrategy}',
    team_benefits: '/v2/team-benefits',
    teams: '/v2/teams',
    user_airmoney: '/v4/users/me/airmoney',
    user_esim_details: 'user/me/sim',
    user_esim_installation: '/v2/users/me/sims/{id}/installation',
    user_esims_type: 'user/me/sim?type={type}',
    user_esims: 'user/me/sim',
    user_info: '/v4/users/me',
    user_referral_benefit: '/v4/users/me/referral/benefit',
    user_referral_earning: '/v4/users/me/referral/earning',
    user_voucher: '/v4/users/me/voucher',
    user_welcome: '/v4/users/me/welcome',
  },
  i18n: {
    fallbackLocale: 'en',
    locale: 'en',
    messages: {},
  },
  partner_join_us_modal: {
    type: '',
    view: false,
  },
})

export const getters = {
  get_app_theme_mode(state) {
    return state.app_theme_mode
  },

  get_auth_dialogs_email(state) {
    return state.auth_dialogs_email
  },

  get_banner_view(state) {
    return state.banner_view
  },

  get_breadcrumb_mapping(state) {
    return state.breadcrumb_mapping
  },

  get_campaign_promotion(state) {
    return state.campaign_promotion
  },

  get_campaign_terms_endpoint(state) {
    return state.endpoints.campaign_terms
  },

  get_cr_key(state) {
    return state.crKey
  },

  get_device_types(state) {
    return state.device_types
  },

  get_footer_countries_links(state) {
    return state.footer_countries_links
  },

  get_ip(state) {
    return state.ip
  },

  get_is_first_login(state) {
    return state.is_first_login
  },

  get_logout_modal_visibility(state) {
    return state.logout_modal_visibility
  },

  get_partner_join_us_modal(state) {
    return state.partner_join_us_modal
  },

  get_searched({ searched }) {
    return searched
  },

  get_should_show_signup_reward(state) {
    return state.should_show_signup_reward
  },

  get_user_current_password(state) {
    return state.user_current_password
  },

  get_user_esims:
    (state) =>
    (type = 'normal') => {
      if (type === 'archived') {
        return state.user_esims.filter((esim) => esim.is_archived)
      } else if (type === 'normal') {
        return state.user_esims.filter((esim) => !esim.is_archived)
      }
      return state.user_esims
    },

  is_authenticated(state) {
    return state.auth.hasOwnProperty('loggedIn') && state.auth.loggedIn
  },

  get_user_welcome(state) {
    return state.user_welcome
  },

  // get_current_breadcrumb_text(state) {
  //   return state.current_breadcrumb_text
  // },

  // get_installation(state) {
  //   return state.installation
  // },

  // get_regions_by_name(state) {
  //   return state.regionsByName
  // },

  // isMobile() {
  //   let check = false
  //   if (navigator || window) {
  //     ;(function (a) {
  //       // START-NOSCAN
  //       if (
  //         /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
  //           a
  //         ) ||
  //         /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
  //           a.substr(0, 4)
  //         )
  //       ) {
  //         check = true
  //       }
  //       // END-NOSCAN
  //     })(navigator.userAgent || navigator.vendor || window.opera)
  //   }
  //   return check
  // },
}

export const mutations = {
  reset_user_esims(state, type) {
    state.user_esims = state.user_esims.filter((eSIM) => (type === 'archived' ? !eSIM.is_archived : eSIM.is_archived))
  },

  set_auth_dialogs_email(state, payload) {
    state.auth_dialogs_email = payload
  },

  set_campaign_promotion(state, payload) {
    state.campaign_promotion = payload
  },

  set_cr_key(state) {
    state.crKey += 1
  },

  set_current_breadcrumb_text(state, payload) {
    state.current_breadcrumb_text = payload
  },

  set_device_types(state, payload) {
    Object.keys(payload).forEach((v) => {
      state.device_types[v] = payload[v]
    })
  },

  set_footer_countries_links(state, payload) {
    state.footer_countries_links = payload
  },

  set_is_first_login(state, payload) {
    state.is_first_login = payload
  },

  set_kyc_verify_modal_data(state, payload) {
    state.kyc_verify_modal_data = payload
  },

  set_loaded_segment(state, payload) {
    if ('archived' in payload) {
      state.loaded_archived_segment = payload.archived
    } else {
      state.loaded_normal_segment = payload.normal
    }
  },

  set_logout_modal_visibility(state, payload) {
    state.logout_modal_visibility = payload
  },

  set_mobile_header_text(state, payload) {
    state.mobile_header_text = payload
  },

  set_partner_join_us_modal(state, payload) {
    state.partner_join_us_modal = payload
  },

  set_searched(state, payload) {
    state.searched = payload
  },

  set_selected_product_type(state, payload) {
    state.selected_product_type = payload
  },

  set_should_show_signup_reward(state, payload) {
    state.should_show_signup_reward = payload
  },

  set_show_preloader(state, payload) {
    state.show_preloader = payload
  },

  set_static_content(state, payload) {
    state.static_content = payload
  },

  set_user_current_password(state, payload) {
    state.user_current_password = payload
  },

  set_user_esims(state, payload) {
    state.user_esims = payload
  },

  set_user_info(state, payload) {
    state.user_info = payload
  },

  set_user_welcome(state, payload) {
    state.user_welcome = payload
  },

  set_export_sar_limit_reached(state) {
    Vue.set(state.auth.user, 'export_sar_limit_reached', true)
  },
}

export const actions = {
  //  nuxtServerInit is left on top, not sorted, because it's a special Nuxt action
  async nuxtServerInit({ dispatch, state, rootGetters, commit }, context) {
    let ip
    if (context.req.headers['x-forwarded-for']) {
      ip = context.req.headers['x-forwarded-for'].split(', ').slice().shift()
    } else {
      ip = context.req.connection.remoteAddress
    }

    dispatch('set_device_types', context.app.$device)
    await dispatch('set_ip', ip)
    await dispatch('fetch_footer_countries_links')

    const cookies = new ServerSideCookies(context.req, context.res)
    const currencyCookie = cookies.get('Airalo.currency')
    await dispatch('multiCurrency/fetch_currencies', currencyCookie)
    await dispatch('multiCurrency/fetch_general_settings')
    await dispatch('behaviors/fetch_behaviors')
    const partner = context.app.router.currentRoute.query?.p || cookies.get('Airalo.partner')
    if (partner) {
      cookies.set('Airalo.partner', partner)
      await dispatch('coBranding/fetch_branding_config', partner, { root: true })
    }

    await dispatch('firebaseFeatureFlags/fetch')
  },

  delete_order_details({ state }, orderId) {
    const url = state.endpoints.order_details_v3.replaceAll('{orderId}', orderId)

    return new Promise((resolve, reject) => {
      this.$axios
        .delete(url)
        .then((response) => {
          const deleteOrderData = response.data

          resolve(deleteOrderData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('delete_order_details', { error })
          reject(error)
        })
    })
  },

  delete_promotion_data({ state }, { checkoutId }) {
    const url = state.endpoints.checkout_promotions.replaceAll('{checkoutId}', checkoutId)

    return new Promise((resolve, reject) => {
      this.$axios
        .delete(url)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('delete_promotion_data', { error })
          reject(error)
        })
    })
  },

  delete_user_credit_card({ state }, creditCardId) {
    const url = state.endpoints.credit_card.replaceAll('{creditCardId}', creditCardId)

    return new Promise((resolve, reject) => {
      this.$axios
        .delete(url)
        .then((response) => {
          resolve(response?.data)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('delete_user_credit_card', { error })
          reject(error)
        })
    })
  },

  fetch_campaign_promotion({ commit }, query) {
    const { utm_campaign, utm_source, utm_medium, utm_term, utm_content } = query
    const url = `/campaign/promotion?utm_campaign=${utm_campaign}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_term=${utm_term}&utm_content=${utm_content}`

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url)
        .then((response) => {
          const campaignPromotionData = response.data

          commit('set_campaign_promotion', campaignPromotionData)
          resolve(response)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_campaign_promotion', { error })
          reject(error)
        })
    })
  },

  fetch_campaign_terms({ state }, campaignSlug) {
    const url = state.endpoints.campaign_terms.replaceAll('{campaignSlug}', campaignSlug)

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url)
        .then((response) => {
          const contentsData = response.data

          resolve(contentsData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_campaign_terms', { error })
          reject(error)
        })
    })
  },

  fetch_checkout_airmoneys({ state }, checkoutId) {
    const url = state.endpoints.checkout_airmoneys.replaceAll('{checkoutId}', checkoutId)

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url)
        .then((response) => {
          const airmoneysData = response.data

          resolve(airmoneysData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_checkout_airmoneys', { error })
          reject(error)
        })
    })
  },

  fetch_checkout_details({ state }, checkoutId) {
    const url = state.endpoints.checkout_details.replaceAll('{checkoutId}', checkoutId)

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url)
        .then((response) => {
          const checkoutDetailsData = response.data

          resolve(checkoutDetailsData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_checkout_details', { error })
          reject(error)
        })
    })
  },

  fetch_contents_group({ state }, groupSlug) {
    const url = state.endpoints.contents_group.replaceAll('{groupSlug}', groupSlug)

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url)
        .then((response) => {
          const contentsData = response.data

          resolve(contentsData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_contents_group', { error })
          reject(error)
        })
    })
  },

  fetch_contents_page({ state }, pageSlug) {
    const url = state.endpoints.contents_page.replaceAll('{pageSlug}', pageSlug)

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url)
        .then((response) => {
          const contentsData = response.data

          resolve(contentsData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_contents_page', { error })
          reject(error)
        })
    })
  },

  fetch_delete_reasons({ state }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(state.endpoints.delete_reasons)
        .then((response) => {
          const reasonsData = response.data

          resolve(reasonsData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_delete_reasons', { error })
          reject(error)
        })
    })
  },

  fetch_esim_topup_packages({ state }, esimId) {
    const url = state.endpoints.esim_topup_packages.replaceAll('{esimId}', esimId)

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url)
        .then((response) => {
          const topupPackagesData = response.data

          resolve(topupPackagesData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_esim_topup_packages', { error })
          reject(error)
        })
    })
  },

  async fetch_footer_countries_links({ state, commit }) {
    let countries = []

    try {
      const response = await this.$axios.get(state.endpoints.footer_countries_links)
      countries = response.data
    } catch (error) {
      if (showConsoleErrors) console.error('fetch_footer_countries_links', { error })
      countries = []
    }

    commit('set_footer_countries_links', countries)
  },

  fetch_jobs({ state }, jobsLimit = 200) {
    const url = state.endpoints.jobs.replaceAll('{jobsLimit}', jobsLimit)

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url)
        .then((response) => {
          const jobsData = response.data.data || []

          resolve(jobsData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_jobs', { error })
          reject(error)
        })
    })
  },

  fetch_my_esim_detail_installation({ state }, { id, pageName, brandName }) {
    const url = state.endpoints.user_esim_installation.replaceAll('{id}', id)
    const headers = {
      'X-OS': pageName,
      'X-Brand': brandName,
      'content-type': 'application/json',
    }

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url, { headers })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_my_esim_detail_installation', { error })
          reject(error)
        })
    })
  },

  fetch_operator_topup_packages({ state }, operatorId) {
    const url = state.endpoints.operator_topup_packages.replaceAll('{operatorId}', operatorId)

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url)
        .then((response) => {
          const topupPackagesData = response.data

          resolve(topupPackagesData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_operator_topup_packages', { error })
          reject(error)
        })
    })
  },

  fetch_order_details({ state }, orderId) {
    const url = state.endpoints.order_details_v4.replaceAll('{orderId}', orderId)

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url)
        .then((response) => {
          const orderDetailsData = response.data

          resolve(orderDetailsData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_order_details', { error })
          reject(error)
        })
    })
  },

  fetch_orders({ state }, nextCursor) {
    const url = nextCursor ? `${state.endpoints.orders}?next_cursor=${nextCursor}` : state.endpoints.orders

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url)
        .then((response) => {
          const responseData = response.data
          const ordersData = responseData?.data

          resolve(ordersData?.length > 0 ? responseData : null)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_orders', { error })
          reject(error)
        })
    })
  },

  fetch_package_details({ state }, packageId) {
    const url = state.endpoints.package_details.replaceAll('{packageId}', packageId)

    return new Promise((resolve, reject) => {
      this.$axios
        .get(url)
        .then((response) => {
          const packageDetailsData = response.data

          resolve(packageDetailsData || null)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_package_details', { error })
          reject(error)
        })
    })
  },

  async fetch_single_static_content({ commit, state }, id) {
    try {
      const content = await this.$axios.get(state.endpoints.contents + '/' + id)

      return content.data
    } catch (error) {
      if (showConsoleErrors) console.error('fetch_single_static_content', { error })
      return null
    }
  },

  fetch_setup_intent_id({ state }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(state.endpoints.setup_intent)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_setup_intent_id', { error })
          reject(error)
        })
    })
  },

  fetch_static_content({ commit, state }) {
    this.$axios
      .get(state.endpoints.contents)
      .then((response) => {
        const contentData = response.data

        contentData.push({ id: 99, title: "FAQ's", isFaq: true })
        for (let node in contentData) {
          contentData[node].text = contentData[node].title
          contentData[node].link = state.content_route_mappings[contentData[node].id]
        }

        commit('set_static_content', contentData)
      })
      .catch((error) => {
        if (showConsoleErrors) console.error('fetch_static_content', { error })
      })
  },

  fetch_team_benefits({ state }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(state.endpoints.team_benefits)
        .then((response) => {
          const teamBenefitsData = response.data

          resolve(teamBenefitsData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_team_benefits', { error })
          reject(error)
        })
    })
  },

  fetch_teams({ state }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(state.endpoints.teams)
        .then((response) => {
          const teamsData = response.data

          resolve(teamsData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_teams', { error })
          reject(error)
        })
    })
  },

  fetch_user_airmoney({ state }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(state.endpoints.user_airmoney)
        .then((response) => {
          const airmoneyData = response.data

          resolve(airmoneyData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_user_airmoney', { error })
          reject(error)
        })
    })
  },

  fetch_user_credit_cards({ state }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(state.endpoints.credit_cards)
        .then((response) => {
          resolve(response.data?.data)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_user_credit_cards', { error })
          reject(error)
        })
    })
  },

  async fetch_user_esims({ commit, state }, type = 'normal') {
    if ((type === 'archived' && state.loaded_archived_segment) || (type === 'normal' && state.loaded_normal_segment)) {
      return
    }

    const url = state.endpoints.user_esims_type.replaceAll('{type}', type)

    commit('reset_user_esims', type)
    commit('set_show_preloader', true)

    return this.$axios
      .get(url)
      .then((response) => {
        commit('set_user_esims', [...state.user_esims, ...response.data])
        commit('set_loaded_segment', { [type]: true })
      })
      .catch((error) => {
        if (showConsoleErrors) console.error('fetch_user_esims', { error })
      })
      .finally(() => {
        commit('set_show_preloader', false)
      })
  },

  async fetch_user_info({ state, commit }) {
    try {
      const response = await this.$axios.get(state.endpoints.user_info)

      commit('set_user_info', {
        ...state.user_info,
        ...response.data,
      })
      this.$auth.setUser({
        ...this.$auth.$state.user,
        ...response.data,
      })
    } catch (error) {
      if (showConsoleErrors) console.error('fetch_user_info', { error })
    }
  },

  fetch_user_referral_earning({ state }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(state.endpoints.user_referral_earning)
        .then((response) => {
          const earningData = response.data

          resolve(earningData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_user_referral_earning', { error })
          reject(error)
        })
    })
  },

  fetch_user_welcome({ state, commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(state.endpoints.user_welcome)
        .then((response) => {
          const welcomeData = response.data

          commit('set_user_welcome', welcomeData)
          resolve(welcomeData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('fetch_user_welcome', { error })
          reject(error)
        })
    })
  },

  post_checkout_complete({ state }, payload) {
    const { checkoutId, checkoutPayload } = payload
    const url = state.endpoints.checkout_complete.replaceAll('{checkoutId}', checkoutId)

    return new Promise((resolve, reject) => {
      this.$axios
        .post(url, checkoutPayload)
        .then((response) => {
          resolve(response.data || null)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('post_checkout_complete', { error })
          reject(error)
        })
    })
  },

  post_checkout_details({ state }, postData) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(state.endpoints.checkouts, postData)
        .then((response) => {
          const checkoutDetailsData = response.data

          if (checkoutDetailsData && Object.keys(checkoutDetailsData).length > 0) {
            resolve(checkoutDetailsData)
          } else {
            resolve(null)
          }
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('post_checkout_details', { error })
          reject(error)
        })
    })
  },

  post_email_verify({ state }, emailVerifyPayload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(state.endpoints.email_verify, emailVerifyPayload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('post_new_credit_card', { error })
          reject(error)
        })
    })
  },

  post_new_credit_card({ state }, creditCardPayload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(state.endpoints.credit_cards, creditCardPayload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('post_new_credit_card', { error })
          reject(error)
        })
    })
  },

  post_payment_data({ state }, payload) {
    const { checkoutId, paymentPayload } = payload
    const url = state.endpoints.checkout_pay.replaceAll('{checkoutId}', checkoutId)

    return new Promise((resolve, reject) => {
      this.$axios
        .post(url, paymentPayload)
        .then((response) => {
          const paymentData = response.data

          resolve(paymentData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('post_payment_data', { error })
          reject(error)
        })
    })
  },

  post_request_export_sar({ state }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(state.endpoints.export_sar)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('post_request_export_sar', { error })
          reject(error)
        })
    })
  },

  post_social_login({ state }, payload) {
    const { authStrategy, socialLoginPayload } = payload
    const url = state.endpoints.social_login.replaceAll('{authStrategy}', authStrategy)

    return new Promise((resolve, reject) => {
      this.$axios
        .post(url, socialLoginPayload)
        .then((response) => {
          const socialLoginData = response.data

          resolve(socialLoginData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('post_social_login', { error })
          reject(error)
        })
    })
  },

  post_user_referral_benefit({ state }, referralCode) {
    const referralPayload = {
      code: referralCode,
    }

    return new Promise((resolve, reject) => {
      this.$axios
        .post(state.endpoints.user_referral_benefit, referralPayload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('post_user_referral_benefit', { error })
          reject(error)
        })
    })
  },

  post_user_voucher({ state }, voucherCode) {
    const voucherHeaders = {
      headers: {
        'X-Support-Sim-Voucher': true,
      },
    }
    const voucherPayload = {
      code: voucherCode.toString().toUpperCase(),
    }

    return new Promise((resolve, reject) => {
      this.$axios
        .post(state.endpoints.user_voucher, voucherPayload, voucherHeaders)
        .then((response) => {
          const voucherData = response.data

          resolve(voucherData)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('post_user_voucher', { error })
          reject(error)
        })
    })
  },

  put_credit_card({ state }, payload) {
    const { creditCardId, creditCardPayload } = payload
    const url = state.endpoints.credit_card.replaceAll('{creditCardId}', creditCardId)

    return new Promise((resolve, reject) => {
      this.$axios
        .put(url, creditCardPayload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('put_credit_card', { error })
          reject(error)
        })
    })
  },

  put_promotion_data({ state }, payload) {
    const { checkoutId, promotionPayload } = payload
    const url = state.endpoints.checkout_promotions.replaceAll('{checkoutId}', checkoutId)

    return new Promise((resolve, reject) => {
      this.$axios
        .put(url, promotionPayload)
        .then((response) => {
          if (response.data) {
            const promotionData = response.data

            resolve(promotionData)
          } else {
            resolve(null)
          }
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('put_promotion_data', { error })
          reject(error)
        })
    })
  },

  put_user_info({ state }, userInfoPayload) {
    const { first_name, last_name, new_password, new_password_confirmation, is_newsletter } = userInfoPayload

    return new Promise((resolve, reject) => {
      this.$axios
        .put(state.endpoints.user_info, {
          first_name,
          last_name,
          new_password,
          new_password_confirmation,
          is_newsletter,
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (showConsoleErrors) console.error('put_user_info', { error })
          reject(error)
        })
    })
  },

  set_device_types({ commit }, payload) {
    const { isDesktop, isMobile, isTablet } = payload

    commit('set_device_types', { isDesktop, isMobile, isTablet })
  },

  set_ip({ state }, ip) {
    state.ip = ip
  },
}
